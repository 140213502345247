const SmoothScroll = require('smooth-scroll');

// Smooth scroll behaviro for the menu links
new SmoothScroll('a[href*="#"]', {
  header: '[data-scroll-header]',
  easing: 'easeInOutCubic',
  speed: 2000,
  topOnEmptyHash: true,
  updateURL: true,
});


// Listener for applying a class to the navbar on scroll
window.addEventListener('scroll', event => {
  const menu = document.getElementById('menu'); 
  const scrollPosY = window.pageYOffset | document.body.scrollTop;

  if(scrollPosY > 50) {
    menu.classList.add(`scrolling`);
  } else if(scrollPosY <= 100) {
    menu.classList.remove(`scrolling`);
  }
});


AOS.init();

var submit = document.querySelector(".submit-form form");

submit.addEventListener('submit',(evt)=>{
  evt.preventDefault();
  var email = document.querySelector('.submit-form input[type=email]');
  var msgEle = document.querySelector('.submit-form .msg');
  var urls={
    dev: 'https://us-central1-fluttergram-5b294.cloudfunctions.net/Signup',
    stage: 'https://us-central1-spot-pack-stage.cloudfunctions.net/Signup',
    production: 'https://us-central1-spot-pack.cloudfunctions.net/Signup',
  }

  if (validateEmail(email, msgEle)){
    fetch(urls['production'], {
      body: "{\"email\":\""+email.value+"\"}",
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST"
    }).then((response)=>{
      console.log("Response");
      console.log(response);
      if(response.ok==true){
        msgEle.innerHTML="<p>Email added.</p>";
        msgEle.classList.add("success","active");
        var s=setTimeout(() => {
          if (msgEle.classList.contains("success")) {
            msgEle.classList.remove("success", "active");
          }
          clearTimeout(s);
        }, 3000);
      }
    })
  }

  
});

function validateEmail(inputText, msgEle) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //console.log(msgEle);
  if (inputText.value.match(mailformat)) {
    //document.form1.text1.focus();
    return true;
  }
  else if(inputText.value){
    msgEle.innerHTML="<p>Email empty.</p>";
    msgEle.classList.add("error", "active");
    var e=setTimeout(() => {
      if (msgEle.classList.contains("error")){
        msgEle.classList.remove("error", "active");
      }
      clearTimeout(e);
    }, 3000);
    return false;
  }
  else {
    msgEle.innerHTML="<p>Email address invalid.</p>";
    msgEle.classList.add("error","active");
    var e = setTimeout(() => {
      if (msgEle.classList.contains("error")) {
        msgEle.classList.remove("error", "active");
      }
      clearTimeout(e);
    }, 3000);
    return false;
  }
}

// Custom JS

